/* Styles for the authentication wrapper */
.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding-top: 80px; /* Add top padding to shift content down */
}

/* Styles specific to the authentication container */
.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Styles for authentication components */
.auth-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 80px;
}

.auth-logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 1.5rem;
}

.auth-title {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.auth-subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2rem;
}

.auth-footer {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.875rem;
  color: #666;
}

/* Customize Amplify UI components for authentication only */
.auth-container [data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #000000;
  --amplify-components-button-primary-hover-background-color: #262626;
  --amplify-components-button-primary-border-color: #000000;
  --amplify-components-button-primary-color: #ffffff;
  --amplify-components-button-primary-disabled-border-color: #cccccc;
  --amplify-components-button-primary-disabled-background-color: #e6e6e6;
  --amplify-components-button-primary-disabled-color: #999999;
  --amplify-components-button-primary-loading-background-color: #b3d8ff;
  --amplify-components-button-primary-loading-border-color: #b3d8ff;
  --amplify-components-button-primary-loading-color: #ffffff;
}

.auth-container [data-amplify-authenticator] [data-amplify-router-content] {
  border: none;
  box-shadow: none;
  padding: 0;
}

.auth-container [data-amplify-authenticator] .amplify-button[type="submit"] {
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0.75rem;
}

.auth-container [data-amplify-authenticator] .amplify-field {
  margin-bottom: 1.25rem;
}

.auth-container [data-amplify-authenticator] .amplify-label {
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.auth-container [data-amplify-authenticator] .amplify-input {
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 6px;
}

.auth-container [data-amplify-authenticator] .amplify-passwordfield__show-password {
  color: #1890ff;
}

/* Specific wrapper for the authentication page */
.auth-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  /* Changed from center to flex-start */
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding-top: 80px; /* This will shift the content down */
}

/* More specific selectors to target the sign-in button */
.auth-container [data-amplify-authenticator] {
  /* Base button styles */
  --amplify-components-button-primary-background-color: #000000;
  --amplify-components-button-primary-hover-background-color: #262626;
  --amplify-components-button-primary-border-color: #000000;
}

/* Add these more specific overrides */
.amplify-button.amplify-field-group__control.amplify-button--primary {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.amplify-button.amplify-field-group__control.amplify-button--primary:hover {
  background-color: #262626 !important;
}

/* If the above doesn't work, try this alternative approach */
[data-amplify-theme] .amplify-button--primary {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

[data-amplify-theme] .amplify-button--primary:hover {
  background-color: #262626 !important;
}

/* Add styling for link buttons */
[data-amplify-theme] .amplify-button--link,
[data-amplify-theme] .amplify-button.amplify-field-group__control.amplify-button--link {
  color: #000000 !important;
  text-decoration: none !important;
  /* Remove default focus/hover styles */
  outline: none !important;
  box-shadow: none !important;
}

[data-amplify-theme] .amplify-button--link:hover,
[data-amplify-theme] .amplify-button.amplify-field-group__control.amplify-button--link:hover {
  color: #262626 !important;
  text-decoration: underline !important;
  background-color: transparent !important;
  /* Remove the blue highlight box */
  box-shadow: none !important;
}

/* Handle focus state for accessibility */
[data-amplify-theme] .amplify-button--link:focus {
  /* Optional: you can add a subtle focus indicator that's not the default blue box */
  outline: none !important;
  box-shadow: none !important;
}
