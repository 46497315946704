body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toolsButtons {
  width: 10em;
}

.amplify-flex.amplify-tabs {
  display: none;
}

svg.signUpIcon {
  font-size: 2.25rem;
  filter: invert(26%) sepia(84%) saturate(4062%) hue-rotate(237deg) brightness(82%) contrast(101%);
}

[data-amplify-router] {
  border-radius: 13px;
}

.hideButton {
  display: none;
}

.centerLink {
  display: flex;
  justify-content: center;
}

.notistack-SnackbarContainer {
  top: 60px !important;
  right: 80px !important;
}
